<template>
    <section class="advanced-form-elements new-updated-design">
        <vue-snotify></vue-snotify>
        <system_app_settings></system_app_settings>
        <br>
        <div class="row">
            <div class="col-md-12 grid-margin">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{ $t('share-settings') }}</h4>
                    </div>
                    <form class="forms-sample" @submit.stop.prevent="onSubmit">
                        <div class="card-body new-card-body">
                            <div class="row">
                                <div class="col-md-6 row-3">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <label class="text-left" for="inline-form-custom-select-pref">{{ $t('show-menu-on-shareable-map') }}</label>
                                        </div>
                                        <div class="col-md-2">
                                            <toggle-button @change="updateShareableSettings()" v-model="form.show_menu" :sync=true class="switchColor"/>
                                        </div> 
                                    </div>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <label class="text-left" for="inline-form-custom-select-pref">{{ $t('show-filter-on-shareable-map') }}</label>
                                        </div>
                                        <div class="col-md-2">
                                            <toggle-button @change="updateShareableSettings()" v-model="form.show_filter" :sync=true class="switchColor"/>
                                        </div> 
                                    </div>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <label class="text-left" for="inline-form-custom-select-pref">{{ $t('show-category-count-on-shareable-map') }}</label>
                                        </div>
                                        <div class="col-md-2">
                                            <toggle-button @change="updateShareableSettings()" v-model="form.show_category_count" :sync=true class="switchColor"/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <label class="text-left" for="inline-form-custom-select-pref">{{ $t('Show Weather Menu') }}</label>
                                        </div>
                                        <div class="col-md-2">
                                            <toggle-button @change="updateShareableSettings()" v-model="form.show_weather_menu" :sync=true class="switchColor"/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <label class="text-left" for="inline-form-custom-select-pref">{{ $t('Show Spots Menu') }}</label>
                                        </div>
                                        <div class="col-md-2">
                                            <toggle-button @change="updateShareableSettings()" v-model="form.show_spots_menu" :sync=true class="switchColor"/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <label class="text-left" for="inline-form-custom-select-pref">{{ $t('Show Surveys Menu') }}</label>
                                        </div>
                                        <div class="col-md-2">
                                            <toggle-button @change="updateShareableSettings()" v-model="form.show_surveys_menu" :sync=true class="switchColor"/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <label class="text-left" for="inline-form-custom-select-pref">{{ $t('Show Spotters Menu') }}</label>
                                        </div>
                                        <div class="col-md-2">
                                            <toggle-button @change="updateShareableSettings()" v-model="form.show_spotters_menu" :sync=true class="switchColor"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center row-3">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label class="text-left" for="inline-form-custom-select-pref">{{ $t('shareable-map') }}: 
                                                <a v-on:click.prevent="copyToClipboard(client_url)" class="button control is-medium">
                                                    <span class="icon">
                                                    <i class="fa fa-clipboard"></i>
                                                    </span>
                                                </a>
                                            </label>
                                            <div>
                                               <a :href="client_url" target="_blank">{{this.client_url}}</a>                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row text-center">
                                        <div class="col-md-12">
                                            <label class="text-left" for="inline-form-custom-select-pref">{{ $t('Website Integration') }}: 
                                                <a v-on:click.prevent="copyToClipboardIframe(client_url)" class="button control is-medium">
                                                    <span class="icon">
                                                    <i class="fa fa-clipboard"></i>
                                                    </span>
                                                </a>
                                            </label>
                                            <label class="_iframe3e">&lt;iframe src="{{ this.client_url }}" width="100%" height="600px"&gt;&lt;/iframe&gt; </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="js">
    import VueTrix from "vue-trix";
    import API from '@/api'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import Vue from "vue";
    import CKEditor from '@ckeditor/ckeditor5-vue2';
    import CkEditorBuild from 'ckeditor5-custom-build/build/ckeditor';
    import UploadAdapter from '@/UploadAdapter';
    import {validationMixin} from "vuelidate";
    import {required, minLength, email, requiredIf, sameAs, between} from "vuelidate/lib/validators";
    import system_app_settings from "./system_app_settings.vue";
    const options = {
        toast: {
            timeout: 2000,
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(CKEditor);
    Vue.use(Snotify, options)
    export default {
        name: 'textEditor',
        mixins: [validationMixin],
        components: {
            system_app_settings
        },
        data() {
            return {
                form: {
                    show_filter: null,
                    show_menu: null,
                    show_category_count: null,
                    show_weather_menu: null,
                    show_spots_menu: null,
                    show_surveys_menu: null,
                    show_spotters_menu: null,
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                },
                clientSettings: [],
                client_url: null,
                client_id: JSON.parse(localStorage.getItem('client_id')),
            }
        },
        mounted() {
            this.getAppSettings();
        },
        methods: {
            copyToClipboard(copyText) {
                console.log('copyText :', copyText);
                const textArea = document.createElement('textarea')
                textArea.textContent = copyText
                document.body.append(textArea)
                textArea.select()
                document.execCommand('copy')
                textArea.remove();
                this.$snotify.success("Copy to clipboard");
            },
            copyToClipboardIframe(clientURL) {
                var ifrm = document.createElement("iframe");
                ifrm.setAttribute("src", clientURL);
                ifrm.style.width = "100%";
                ifrm.style.height = "600px";
                this.copyToClipboard(ifrm.outerHTML);
            },
            updateShareableSettings() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    show_category_count: this.form.show_category_count,
                    show_filter: this.form.show_filter,
                    show_menu: this.form.show_menu,
                    show_weather_menu: this.form.show_weather_menu,
                    show_spots_menu: this.form.show_spots_menu,
                    show_surveys_menu: this.form.show_surveys_menu,
                    show_spotters_menu: this.form.show_spotters_menu
                };

                API.updateShareableSettings(
                    data,
                    data => {
                        if (data.status === 200) {
                            this.$snotify.success(data.message);
                            // Emit the event with updated settings
                            this.$EventBus.$emit('settings-updated', {
                                show_weather_menu: this.form.show_weather_menu,
                                show_spots_menu: this.form.show_spots_menu,
                                show_surveys_menu: this.form.show_surveys_menu,
                                show_spotters_menu: this.form.show_spotters_menu
                            });
                        }
                    },
                    err => {}
                );
            },
            getClientSettings() {
                this.clientSettings = JSON.parse(localStorage.getItem('client_settings'));
                if (this.clientSettings) {
                    this.client_url = this.clientSettings.url;
                }
            },
            getAppSettings() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getAppSettings(
                    data,
                    data => {
                        this.form.show_menu = (data.data.show_menu === 1) ? true : false;
                        this.form.show_filter = (data.data.show_filter === 1) ? true : false;
                        this.form.show_category_count = (data.data.show_category_count === 1) ? true : false;
                        this.form.show_weather_menu = (data.data.show_weather_menu === 1) ? true : false;
                        this.form.show_spots_menu = (data.data.show_spots_menu === 1) ? true : false;
                        this.form.show_surveys_menu = (data.data.show_surveys_menu === 1) ? true : false;
                        this.form.show_spotters_menu = (data.data.show_spotters_menu === 1) ? true : false;
                    },
                    err => {
                    }
                )
            },
        }
        ,
        beforeMount() {

            this.getAppSettings();
            this.getClientSettings();
        }
    }
</script>
<style scoped>
    .document-editor {
        border: 1px solid var(--ck-color-base-border);
        border-radius: var(--ck-border-radius);

        /* Set vertical boundaries for the document editor. */
        max-height: 700px;

        /* This element is a flex container for easier rendering. */
        display: flex;
        flex-flow: column nowrap;
    }

    .document-editor__toolbar {
        /* Make sure the toolbar container is always above the editable. */
        z-index: 1;

        /* Create the illusion of the toolbar floating over the editable. */
        box-shadow: 0 0 5px hsla(0, 0%, 0%, .2);

        /* Use the CKEditor CSS variables to keep the UI consistent. */
        border-bottom: 1px solid var(--ck-color-toolbar-border);
    }
    /* Adjust the look of the toolbar inside the container. */
    .document-editor__toolbar .ck-toolbar {
        border: 0;
        border-radius: 0;
    }

    /* Make the editable container look like the inside of a native word processor application. */
    .document-editor__editable-container {
        padding: calc(2 * var(--ck-spacing-large));
        background: var(--ck-color-base-foreground);

        /* Make it possible to scroll the "page" of the edited content. */
        overflow-y: scroll;
    }

    .document-editor__editable-container .ck-editor__editable {
        /* Set the dimensions of the "page". */
        width: 15.8cm;
        min-height: 21cm;

        /* Keep the "page" off the boundaries of the container. */
        padding: 1cm 2cm 2cm;

        border: 1px hsl(0, 0%, 82.7%) solid;
        border-radius: var(--ck-border-radius);
        background: white;

        /* The "page" should cast a slight shadow (3D illusion). */
        box-shadow: 0 0 5px hsla(0, 0%, 0%, .1);

        /* Center the "page". */
        margin: 0 auto;
    }

    /* Set the default font for the "page" of the content. */
    .document-editor .ck-content,
    .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
        font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    /* Adjust the headings dropdown to host some larger heading styles. */
    .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
        line-height: calc(1.7 * var(--ck-line-height-base) * var(--ck-font-size-base));
        min-width: 6em;
    }

    /* Scale down all heading previews because they are way too big to be presented in the UI.
    Preserve the relative scale, though. */
    .document-editor .ck-heading-dropdown .ck-list .ck-button:not(.ck-heading_paragraph) .ck-button__label {
        transform: scale(0.8);
        transform-origin: left;
    }

    /* Set the styles for "Heading 1". */
    .document-editor .ck-content h2,
    .document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
        font-size: 2.18em;
        font-weight: normal;
    }

    .document-editor .ck-content h2 {
        line-height: 1.37em;
        padding-top: .342em;
        margin-bottom: .142em;
    }

    /* Set the styles for "Heading 2". */
    .document-editor .ck-content h3,
    .document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
        font-size: 1.75em;
        font-weight: normal;
        color: hsl(203, 100%, 50%);
    }

    .document-editor .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
        color: var(--ck-color-list-button-on-text);
    }

    /* Set the styles for "Heading 2". */
    .document-editor .ck-content h3 {
        line-height: 1.86em;
        padding-top: .171em;
        margin-bottom: .357em;
    }

    /* Set the styles for "Heading 3". */
    .document-editor .ck-content h4,
    .document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
        font-size: 1.31em;
        font-weight: bold;
    }

    .document-editor .ck-content h4 {
        line-height: 1.24em;
        padding-top: .286em;
        margin-bottom: .952em;
    }

    /* Set the styles for "Paragraph". */
    .document-editor .ck-content p {
        font-size: 1em;
        line-height: 1.63em;
        padding-top: .5em;
        margin-bottom: 1.13em;
    }

    /* Make the block quoted text serif with some additional spacing. */
    .document-editor .ck-content blockquote {
        font-family: Georgia, serif;
        margin-left: calc(2 * var(--ck-spacing-large));
        margin-right: calc(2 * var(--ck-spacing-large));
    }

    .ck.ck-editor__editable_inline {
        border: 1px solid #e3e3e3;
        overflow: auto;
        padding: 0 var(--ck-spacing-standard);
    }

.btn-2 {
  display: flex;
  align-items: center;
  --background: #2b3044;
  --background-hover: #1e2235;
  background: none;
  border: 1px solid lighten(gray, 24%);
  height: 48px;
  padding: 0 24px 0 16px;
  letter-spacing: .25px;
  border-radius: 18px;
  cursor: pointer;
}

.btn-delete {
  font-size: 12px;
  color: red;
}

.button-62 {
  background: linear-gradient(to bottom right, #EF4765, #FF9A5A);
  border: 0;
  border-radius: 12px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.5;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.btn-2:not([disabled]):focus {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}

.btn-2:not([disabled]):hover {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}
.row-3 .row {
    margin-top: 10px;
    margin-bottom: 20px;
}
._iframe3e {
    border: 1px solid;
    padding: 20px;
    width: 460px;
}
@media (max-width: 1150px) {
    .card .new-card-body {
        padding: 1.25rem 1.437rem !important;
    }
}
</style>
