<template>
    <section class="app-sidebar left-sidebar">
        <nav class="sidebar sidebar-offcanvas" id="sidebar">
            <div class="version-name showVersionName" v-if="version_name" :class="version_code">
                <span class="nav-link">{{ version_name }}</span>
            </div>
            <div class="version-code showVersionCode" v-if="version_code" :class="version_code">
                <span class="nav-link">{{ version_code }}</span>
            </div>
            <ul class="nav my-nav">
                <li class="nav-item" v-if="permissionExist('view_super_admin_dashboard')" v-on:click="collapseAll">
                    <router-link class="nav-link" to="/dashboard">
                        <i class="ti-dashboard menu-icon"></i>
                        <span class="menu-title">{{$t('dashboard')}}</span>
                    </router-link>
                </li>

                <li class="nav-item" v-else-if="permissionExist('view_clients_dashboard')">
                    <span class="nav-link" v-b-toggle="'dashboards'">
                        <i class="ti-dashboard menu-icon"></i>
                        <span class="menu-title">{{ $t('dashboards') }}</span>
                        <i class="menu-arrow"></i>
                    </span>
                    <b-collapse accordion="sidebar-accordion" id="dashboards">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item">
                                <router-link class="nav-link" to="/dashboard">{{ $t('project') }}</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" to="/spotter/dashboard">{{ $t('spotter') }}</router-link>
                            </li>
                        </ul>
                    </b-collapse>
                </li>
                <li class="nav-item" v-if="permissionExist('clients')" v-on:click="collapseAll">
                    <router-link class="nav-link" to="/clients/">
                        <i class="ti-user menu-icon"></i>
                        <span class="menu-title">{{ $t('clients') }}</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="permissionExist('roles')" v-on:click="collapseAll">
                    <router-link class="nav-link" to="/user/roles/">
                        <i class="ti-lock menu-icon"></i>
                        <span class="menu-title">{{ $t('role-and-permissions') }}</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="permissionExist('users')">
                    <span class="nav-link" v-b-toggle="'user'">
                        <i class="ti-user menu-icon"></i>
                        <span class="menu-title">{{ $t('users') }}</span>
                        <i class="menu-arrow"></i>
                    </span>
                    <b-collapse accordion="sidebar-accordion" id="user">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item" v-if="permissionExist('users')">
                                <router-link class="nav-link" to="/user/users/">Users</router-link>
                            </li>
                        </ul>
                    </b-collapse>
                </li>
                <li class="nav-item" v-if="permissionExist('satellite_map') || permissionExist('heat_map')">
                    <span class="nav-link" v-b-toggle="'maps'">
                        <i class="ti-map-alt menu-icon"></i>
                        <span class="menu-title">{{ $t('maps') }}</span>
                        <i class="menu-arrow"></i>
                    </span>
                    <b-collapse accordion="sidebar-accordion" id="maps">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item" v-if="permissionExist('satellite_map')">
                                <router-link class="nav-link" to="/satellite_map">{{ $t('interactive-maps') }}</router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('heat_map')">
                                <router-link class="nav-link" to="/heat/map">{{ $t('heat-map') }}</router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('bathymetry_map') && version_code !== 'CS'">
                                <router-link class="nav-link" to="/bathymetry/map">{{ $t('bathymetry-map') }}</router-link>
                            </li>
                        </ul>
                    </b-collapse>
                </li>
                <li class="nav-item" v-if="permissionExist('weather') && version_code !== 'CS' && show_weather_menu" v-on:click="collapseAll">
                    <router-link class="nav-link" to="/weather">
                        <i class="fa-solid fa-temperature-half menu-icon"></i>
                        <span class="menu-title">{{ $t('weather') }}</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="permissionExist('spots') && show_spots_menu" v-on:click="collapseAll">
                    <router-link class="nav-link" to="/spots">
                        <i class="ti-location-pin menu-icon" aria-hidden="true"></i>
                        <span class="menu-title">{{ $t('spots') }}</span>
                    </router-link>
                </li>

                <li class="nav-item" v-if="permissionExist('spots') && version_code !== 'CS' && show_surveys_menu" v-on:click="collapseAll">
                    <router-link class="nav-link" to="/survey/summary">
                        <i class="fas fa-poll menu-icon"></i>
                        <span class="menu-title">{{ $t('surveys') }}</span>
                    </router-link>
                </li>
                <li class="nav-item"
                v-if="(permissionExist('spotters') || permissionExist('notifications') || permissionExist('welcome')) && show_spotters_menu">
                    <span class="nav-link" v-b-toggle="'spotters'">

                        <i class="fa fa-binoculars menu-icon" aria-hidden="true"></i>
                        <span class="menu-title">{{ $t('spotters') }}</span>
                        <i class="menu-arrow"></i>
                    </span>
                    <b-collapse accordion="sidebar-accordion" id="spotters">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item" v-if="permissionExist('spotters')">
                                <router-link class="nav-link" to="/spotter">{{ $t('spotters') }}</router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('notifications')">
                                <router-link class="nav-link" to="/spotter/notifications">{{ $t('notifications') }}</router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('welcome')">
                                <router-link class="nav-link" to="/spotter/welcome">{{ $t('welcome') }}</router-link>
                            </li>
                        </ul>
                    </b-collapse>
                </li>

                <li class="nav-item"
                    v-if="permissionExist('app_settings') || permissionExist('field_guide') || permissionExist('email_settings')">
                    <span class="nav-link" v-b-toggle="'settings'">
                        <i class="ti-settings menu-icon"></i>
                        <span class="menu-title">{{ $t('settings') }}</span>
                        <i class="menu-arrow"></i>
                    </span>
                    <b-collapse accordion="sidebar-accordion" id="settings">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item" v-if="permissionExist('system_settings')">
                                <router-link class="nav-link" to="/app/share/settings">{{ $t('app-settings') }}</router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('field_guide')">
                                <router-link class="nav-link" to="/app/field_guide">{{ $t('field-guide') }}</router-link>
                            </li>
<!--                            <li class="nav-item" v-if="permissionExist('email_settings')">-->
<!--                                <router-link class="nav-link" to="/email/settings">Email Settings</router-link>-->
<!--                            </li>-->

                        </ul>
                    </b-collapse>

                </li>

                <li class="nav-item"
                    v-if="permissionExist('categories') || permissionExist('locations') ||  permissionExist('spot_type') ||  permissionExist('questions')">
                    <span class="nav-link" v-b-toggle="'data_collections'">
                        <i class="fa-solid fa-comments menu-icon"></i>
                        <span class="menu-title">{{ $t('data-collection') }}</span>
                        <i class="menu-arrow"></i>
                    </span>
                    <b-collapse accordion="sidebar-accordion" id="data_collections">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item" v-if="permissionExist('categories')">
                                <router-link class="nav-link" to="/categories">{{ $t('categories') }}</router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('locations')">
                                <router-link class="nav-link" to="/locations">{{ $t('locations') }}</router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('spot_type')">
                                <router-link class="nav-link" to="/spot/types">{{ $t('spot-types') }}</router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('questions')">
                                <router-link class="nav-link" to="/data/collection/questions">{{ $t('data-collection-questions') }}
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('questions')">
                                <router-link class="nav-link" to="/data/post-process/questions">{{ $t('post-process-data') }}
                                </router-link>
                            </li>
                            <!--<li class="nav-item" v-if="permissionExist('surveys') && version_code !== 'CS'">
                                <router-link class="nav-link" to="/survey/settings">{{ $t('Survey Settings') }}
                                </router-link>
                            </li> -->
                            <li class="nav-item" v-if="permissionExist('surveys') && version_code !== 'CS'">
                                <router-link class="nav-link" to="/surveys">{{ $t('survey-setup') }}
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('surveys') && version_code !== 'CS'">
                                <router-link class="nav-link" to="/survey/fields">{{ $t('survey-fields') }}
                                </router-link>
                            </li>
                            <!-- <li class="nav-item" v-if="permissionExist('questions')">
                                <router-link class="nav-link" to="/questions/general">{{ $t('General Questions') }}
                                </router-link>
                            </li> -->

                        </ul>
                    </b-collapse>
                </li>

                <!-- <li class="nav-item"
                    v-if="permissionExist('acoustic_category') || permissionExist('acoustic_location') ||  permissionExist('acoustic_type') ||  permissionExist('acoustic_questions')">
                    <span class="nav-link" v-b-toggle="'acoustic_category'">
                        <i class="fa-solid fa-microphone menu-icon" aria-hidden="true"></i>
                        <span class="menu-title">Acoustics</span>
                        <i class="menu-arrow"></i>
                    </span>
                    <b-collapse accordion="sidebar-accordion" id="acoustic_category">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item" v-if="permissionExist('acoustic_category')">
                                <router-link class="nav-link" to="/acoustic/categories">Categories</router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('acoustic_location')">
                                <router-link class="nav-link" to="/acoustic/locations">Locations</router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('acoustic_type')">
                                <router-link class="nav-link" to="/acoustic/spotTypes">Spot Type</router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('acoustic_questions')">
                                <router-link class="nav-link" to="/data/collection/acousticQuestions">Data Collection
                                    Questions
                                </router-link>
                            </li>
                        </ul>
                    </b-collapse>
                </li> -->

                <li class="nav-item" v-if="permissionExist('data_export')" v-on:click="collapseAll">
                    <router-link class="nav-link" to="/reports">
                        <i class="fa-solid fa-file-excel menu-icon" aria-hidden="true"></i>
                        <span class="menu-title">{{ $t('reports') }}</span>
                    </router-link>
                </li>
                <li class="nav-item"
                v-if="permissionExist('projects') && version_code === 'PRO' && this.is_pro_sync === 1">
                    <span class="nav-link" v-b-toggle="'pro_sync'">
                        <i class="fas fa-sync menu-icon"></i>
                        <span class="menu-title">{{ $t('ProSync Setup') }}</span>
                        <i class="menu-arrow"></i>
                    </span>
                    <b-collapse accordion="sidebar-accordion" id="pro_sync">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item" v-if="permissionExist('projects')">
                                <router-link class="nav-link" to="/projects">
                                    {{ $t('Projects') }}
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('projects')">
                                <router-link class="nav-link" to="/projects/setup">
                                    {{ $t('Observers') }}
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('projects')">
                                <router-link class="nav-link" to="/projects/weather">
                                    {{ $t('Environment') }}
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('projects')">
                                <router-link class="nav-link" to="/projects/effort">
                                    {{ $t('Effort') }}
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('projects')">
                                <router-link class="nav-link" to="/projects/survey">
                                    {{ $t('survey') }}
                                </router-link>
                            </li>
                           <li class="nav-item" v-if="permissionExist('projects')">
                                <router-link class="nav-link" to="/projects/sighting">
                                    {{ $t('Sighting') }}
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('projects')">
                                <router-link class="nav-link" to="/projects/reports">
                                    {{ $t('reports') }}
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('projects')">
                                <router-link class="nav-link" to="/projects/settings">
                                    {{ $t('settings') }}
                                </router-link>
                            </li>
                        </ul>
                    </b-collapse>
                </li>
                <!-- <li class="nav-item" v-if="permissionExist('spotters')">
                    <router-link class="nav-link" to="/chat">
                        <i class="fas fa-comment-dots menu-icon"></i>
                        <span class="menu-title">{{ $t('messaging') }}</span>
                    </router-link>
                </li> -->
                <!-- <li class="nav-item" v-if="permissionExist('spotters')">
                    <router-link class="nav-link" to="/chat2">
                        <i class="fa-brands fa-rocketchat menu-icon"></i>
                        <span class="menu-title">Spotter Chat</span>
                    </router-link>
                </li> -->
                <!-- <li class="nav-item" v-if="permissionExist('spotters')">
                    <a class="nav-link" href="https://support.watchspotter.net/" target="_blank">
                        <i class="fa-solid fa-life-ring menu-icon"></i>
                        <span class="menu-title">{{ $t('help') }}</span>
                    </a>
                </li> -->
                <!-- <li class="nav-item" v-if="permissionExist('data_import')" v-on:click="collapseAll">
                    <router-link class="nav-link" to="/">
                        <i class="ti-import menu-icon"></i>
                        <span class="menu-title">Data Import</span>
                    </router-link>
                </li> -->
            </ul>
        </nav>
    </section>
</template>

<script>
    import i18n from '@/i18n';
    import API from '@/api';
    import Snotify, {SnotifyPosition} from 'vue-snotify';

    export default {
        name: "sidebar",
        data() {
            return {
                version_code: '',
                version_name: '',
                is_pro_sync: '',
                collapses: [{show: false}, {show: false}, {show: false}],
                getPermissionsData: [],
                client_url: window.location.href.split('#')[0],
                show_weather_menu: null,
                show_spots_menu: null,
                show_surveys_menu: null,
                show_spotters_menu: null,
            };
        },
        methods: {
            getPermissions() {
                this.getPermissionsData = JSON.parse(localStorage.getItem('userPermissions'));
            },
            getClient() {
                const data = {
                    id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.get_client(
                    data,
                    data => {
                        if(data.version) {
                            this.version_name = data.version.version_name;
                            this.version_code = data.version.version_code;
                            this.is_pro_sync = data.is_pro_sync;
                        }
                    },
                    err => {})
            },
            permissionExist(param) {
                var check = this.getPermissionsData.includes(param);
                if (check) {
                    return true;
                } else {
                    return false;
                }
            },
            collapseAll() {
                var exp_elm = document.getElementsByClassName("show");
                if (exp_elm.length > 0) {
                    var elm_id = exp_elm[0].id;
                    this.$root.$emit("bv::toggle::collapse", elm_id);
                }
            },
            // activeParent() {
            //     document.querySelector(".nav-link.active").classList.parent.a.addClass("sub-nav-item");
            // }
            getAppSetting() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getAppSettings(
                data,
                data => {
                    this.show_weather_menu = data.data.show_weather_menu;
                    this.show_spots_menu = data.data.show_spots_menu;
                    this.show_surveys_menu = data.data.show_surveys_menu;
                    this.show_spotters_menu = data.data.show_spotters_menu;
                },
                err => {
                })
            },
        },
        mounted() {

            const body = document.querySelector("body");
            // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
            document.querySelectorAll(".sidebar .nav-item").forEach(function (el) {
                el.addEventListener("mouseover", function () {
                    if (body.classList.contains("sidebar-icon-only")) {
                        el.classList.add("hover-open");
                    }
                });
                el.addEventListener("mouseout", function () {
                    if (body.classList.contains("sidebar-icon-only")) {
                        el.classList.remove("hover-open");
                    }
                });
            });
            console.log("Mounted: Waiting for 'settings-updated' event...");

            // Listen for settings update
            this.$EventBus.$on('settings-updated', (updatedSettings) => {
                this.show_weather_menu = updatedSettings.show_weather_menu;
                this.show_spots_menu = updatedSettings.show_spots_menu;
                this.show_surveys_menu = updatedSettings.show_surveys_menu;
                this.show_spotters_menu = updatedSettings.show_spotters_menu;
            });

        this.getAppSetting();

        }, beforeMount() {
            this.getPermissions();
            var client_id = JSON.parse(localStorage.getItem('client_id'));
            if(client_id !== null) {
                this.getClient();
            }
        },
        watch: {
            $route() {
                document.querySelector("#sidebar").classList.toggle("active");
            }
        }
    };

    // $(document).ready(function(){ 
    //     $(".nav-link").click(function(){ 
    //         $(".nav-link.active").parent().addClass('sub-nav-item');
    //     })
    // });

</script>

<style>
.sidebar-icon-only .nav.sub-menu .nav-item .nav-link {
    padding: 0.7rem 1rem !important;
}

.sidebar .my-nav .nav-item .nav-link i.menu-icon {
    width: 16px;
    /* height: 17px; */
}


/* .sidebar .my-nav .nav-item .nav-link .menu-title {
    height: 10px;
} */
/* .sidebar .my-nav .nav-item .nav-link i.menu-arrow{
    width: 16px;
    height: 17px;
} */

/* .sidebar .my-nav .nav-item .nav-link .menu-title {
    height: 12px;
}

.sidebar .my-nav .nav-item .nav-link i.menu-arrow {
    height: 17px;
} */


.sidebar .my-nav .nav-item:hover .nav-link {
    transform: translateZ(0) !important;
    transition: transform 0.8s ease-in-out;
    color: #787878 !important;
}

/* .sidebar .nav.sub-menu .nav-item:hover .nav-link {
    transform: translateZ(10px) !important;
    transition: transform .5s ease;
    color: #656565 !important;
} */

/* .left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link{
    background-color: transparent !important;
    color: #787878 !important;
} */

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link[aria-expanded=true] {
    /* background-color: #248afd !important;
    color: #fff !important; */
    border-radius: 4px !important;
    background-color: #ededed !important;
    color: #787878 !important;
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link {
    border-radius: 4px !important;
    background-color: #ededed !important;
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item>.nav-link[aria-expanded=true] {
    border-radius: 4px !important;
    background-color: #ededed !important;
    color: #787878 !important;
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item>.nav-link[aria-expanded=true]>i {
    color: #787878 !important;
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item>.nav-link[aria-expanded=true]>span {
    color: #787878 !important;
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item>.nav-link.active {
    background-color: #248afd !important;
    box-shadow: 0 0 10px 1px rgba(1,103,240,.7);
    color: #fff !important;
    border-radius: 4px !important;
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item>.nav-link.active>span {
    color: #fff;
}
.sidebar-icon-only .sidebar .nav:not(.sub-menu)>.nav-item>.nav-link.active>span {
    color: #666 !important;
}
.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item.hover-open>.nav-link.active>span {
    color: #666 !important;
}
.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item>.nav-link.active>i {
    color: #fff !important;
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link.active>span {
    color: #fff;
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link.active>i {
    color: #fff !important;
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link>span {
    transform: translateX(5px);
    color: #fff !important;
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link>.menu-icon {
    transform: translateX(5px);
    color: #fff !important;
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item>.nav-link>span {
    transition: transform 0.5s ease-in-out;
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item>.nav-link>.menu-icon {
    transition: transform 0.5s ease-in-out;
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link>span {
    color: #787878 !important;
    transform: translateX(5px);
    /* transition: all 0.5s ease-in-out; */
}
.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link>.menu-icon {
    color: #787878 !important;
    transform: translateX(5px);
    /* transition: all 0.5s ease-in-out; */
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link.active>span {
    /* color: #fff !important; */
    transform: translateX(0px);
    /* transition: all 0.5s ease-in-out; */
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link.active>i {
    color: #fff !important;
    transform: translateX(0px);
    /* transition: all 0.5s ease-in-out; */
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link[aria-expanded=true]>.menu-icon {
    color: #787878 !important;
    transform: translateX(0px);
    /* transition: all 0.5s ease-in-out; */
}
.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link[aria-expanded=true]>span {
    color: #787878 !important;
    transform: translateX(0px);
    /* transition: all 0.5s ease-in-out; */
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link[aria-expanded=true]>i {
    color: #787878 !important;
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link>span {
    color: #787878 !important;
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link>.menu-icon {
    color: #787878 !important;
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link>i {
    color: #787878 !important;
}

.left-sidebar .sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link>i{
    color: #787878 !important;
}

/* .sidebar .nav.sub-menu .nav-item:hover .nav-link {
    transform: translateX(5px) !important;
    transition: all .5s ease;
    color: #656565 !important;
} */

/* .sidebar .nav.sub-menu .nav-item:hover {
    transform: translateX(5px) !important;
    transition: all .5s ease;
    color: #656565 !important;
} */

.sidebar .nav.sub-menu .nav-item:hover .nav-link {
    transform: translateX(5px) !important;
    color: #656565 !important;
}

.sidebar .nav.sub-menu .nav-item .nav-link {
    transition: transform 0.5s ease-in-out;
}

.sidebar .nav.sub-menu .nav-item:hover .nav-link.active {
    transform: translateX(0px) !important;
    transition: all 0.5s ease-in-out;
    color: #656565 !important;
}

.sidebar .nav.sub-menu .nav-item:before:hover {
    transform: translateX(5px) !important;
    transition: all 0.5s ease-in-out;
}

.sidebar .nav.sub-menu .nav-item .nav-link:hover {
    color: #656565 !important;
}

.sidebar .nav.sub-menu .nav-item .nav-link.active {
    background-color: #248afd !important;
    color: #fff !important;
    box-shadow: 0 0 10px 1px rgba(1,103,240,.7);
    border-radius: 4px !important;
}

/* .sidebar .nav.sub-menu .nav-item {
    padding: 0.25rem 0 0 3.07rem !important;
} */

.sidebar .nav.sub-menu .nav-item .nav-link {
    padding-left: 58px !important;
}

.sidebar .nav.sub-menu .nav-item:hover .nav-link {
    color: #656565 !important;
}

.sidebar .nav.sub-menu .nav-item:hover .nav-link.active {
    color: #fff !important;
}

.sidebar .nav.sub-menu {
    padding: 0 !important;
}

.sidebar .nav.sub-menu {
    background: #fff !important;
}

/* .sidebar .nav.sub-menu .nav-item::before .nav-link.active {
    border: 1px solid #656565;
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    margin-top: 14px;
    border-radius: 50%;
    background: #fff;
    margin-left: 40px;
    z-index: 2;
} */

.sidebar .nav.sub-menu .nav-item::before {
    display: none;
}

.sidebar .nav.sub-menu .nav-item .nav-link::after {
    border: 1px solid #656565;
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    margin-top: 0px;
    border-radius: 50%;
    background: #fff;
    left: 42px;
    z-index: 2;
}
.left-sidebar {
    position: fixed;
    z-index: 9;
}
.main-view .client-dashbboard .main-panel {
    /* margin-left: 237px; */
    margin-left: auto;
}

@media (max-width: 992px) {
    .main-view .client-dashbboard .main-panel {
        margin-left: 0px;
    }
}

.client-dashbboard .panel-width {
    width: calc(100% - 70px) !important;
}

.version-name, .version-code {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: #FFFF;
    background: #949398FF;
    font-size: 16px;
    animation: hideMe 2s forwards;
    animation: showMe 2s forwards;
}

@keyframes hideMe{
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
@keyframes showMe{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.CS {
    background: #949398FF;
}

.PRO {
    background: #2BAE66FF !important;
}

.EV {
    background: #2BAE66FF !important;
}
.PRO_SYNC {
    background: #2BAE66FF !important;
}

/* .sidebar .nav.sub-menu .nav-item:hover::before {
    transform: translateX(5px);
    transition: all .5s ease;
} */


/* .left-sidebar .sidebar .nav.sub-menu>.nav-item:hover>.nav-link {
    transform: translateX(5px) !important;
    transition: all .5s ease;
}

.left-sidebar .sidebar .nav.sub-menu>.nav-item:hover>.nav-link.active {
    transform: translateX(5px) !important;
    transition: all .5s ease;
} */

</style>