import Vue from "vue";


// var API_URL = 'http://127.0.0.1:8000/api/'
// var API_URL_CIT = 'http://127.0.0.1:8000/api/project/'

// var API_URL = 'http://watch-spotter.test/api/'
// var API_URL_CIT = 'http://watch-spotter.test/api/project/'

var API_URL = process.env.VUE_APP_ENDPOINT + '/api/'
var API_URL_CIT = process.env.VUE_APP_ENDPOINT + '/api/project/'

// var API_URL = 'https://test.watchspotter.app/api/'
// var API_URL_CIT = 'https://test.watchspotter.app/api/project/'

// var API_URL = '/api/'
// var API_URL_CIT = '/api/project/'
import axios from 'axios'

window.axios = axios;
window.axios.interceptors.request.use(request => {
    var citizenVal = false;
    if (request.url.indexOf("project") > -1) {
        citizenVal = true
    }
    if (citizenVal === true || request.url === API_URL_CIT + 'register/spotter' || request.url === API_URL_CIT + 'login/spotter') {
        const token = localStorage.getItem('spotterLoggedIn');
        if (token) {
            request.headers.common['Authorization'] = `Bearer ${token}`
            request.headers.common['Content-Type'] = 'application/json'
        }
    }
    return request
});


const API = {
    saveDefaultMap(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'saveDefaultMap', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.data)
            })
    },
    addSpot(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'addSpot', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err)
            })
    },
    addAcousticSpot(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'addAcousticSpot', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err)
            })
    },
    getSpotterSpotPerMonth(data, cb, errorCB) {
        axios.post(API_URL_CIT + 'getSpotterSpotPerMonth', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getSpotPerLocation(data, cb, errorCB) {
        axios.post(API_URL_CIT + 'getSpotterSpotPerLocation', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getSpotPerType(data, cb, errorCB) {
        axios.post(API_URL_CIT + 'getSpotterSpotPerType', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getSpotterQuestionsData(data, cb, errorCB) {
        axios.post(API_URL_CIT + 'getSpotterQuestionsData', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getSpotterCategoryPerType(data, cb, errorCB) {
        axios.post(API_URL_CIT + 'getSpotterCategoryPerType', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    forgetSpotterPassword(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'forgetPassword', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.data)
            })
    },
    getSpotterSpotCount(params, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'getSpotterSpotCount', params)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    resetSpotterPassword(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'resetSpotterPassword', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.data)
            })
    },
    registerSpotter(data, cb, errorCB) {
        axios
            .post(API_URL + 'register/spotter', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err)
            })
    },
    loginSpotter(data, cb, errorCB) {
        axios
            .post(API_URL + 'login/spotter', data)
            .then(resp => {
                if (resp.status == 201) {
                    cb(resp.data)
                } else {
                    cb(resp.data)
                }

            })
            .catch(err => {

                errorCB(err.response.data)
            })
    },
    getRecentSpots(data, cb, errorCB) {
        axios
            .post(API_URL + 'getRecentSpots', data)
            .then(resp => {
                if (resp.status == 201) {
                    cb(resp.data)
                } else {
                    cb(resp.data)
                }

            })
            .catch(err => {
                errorCB(err)
            })
    },
    getSpotDetail(data, cb, errorCB) {
        axios.post(API_URL + 'getSpotDetail', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getSpotterProfile(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'getSpotterProfile', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    updateSpotterProfile(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'updateSpotterProfile', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getSpotterDashboardData(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'getSpotterDashboardData', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getSpotType(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'getSpotType', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err)
            })
    },
    getAcousticSpotType(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'getAcousticSpotType', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err)
            })
    },
    getAppSetting(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'getAppSetting', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err)
            })
    },
    getLocations(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'getLocations', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err)
            })
    },
    getAcousticLocations(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'getAcousticLocations', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err)
            })
    },
    dataCollectionQuestions(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'dataCollectionQuestions', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err)
            })
    },
    dataAcousticCollectionQuestions(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'dataAcousticCollectionQuestions', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err)
            })
    },
    logoutCitizen(cb, errorCB) {
        axios
            .post(API_URL_CIT + 'logout')
            .then(resp => {
                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getClientSpots(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'getClientSpots1', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.data)
            })
    },
    getAddSpotCategoriesForSpotter(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'getAddSpotCategoriesForSpotter', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err)
            })
    },
    getAddAcousticCategories(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'getAddAcousticCategories', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err)
            })
    },
    getPageTitle(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'getPageTitle', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err)
            })
    },
    getClientSpotss(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'getClientSpotss', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.data)
            })
    },
    getFiltersData(data, cb, errorCB) {
        axios.post(API_URL_CIT + 'getFiltersData', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err)
            })
    },
    getQuestionsAnswers(data, cb, errorCB) {
        axios.post(API_URL_CIT + 'getQuestionsAnswers', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err)
            })
    },
    getQuestionAnswerCounts(data, cb, errorCB) {
        axios.post(API_URL_CIT + 'getQuestionAnswerCounts', data)
            .then(resp => {

                cb(resp.data)
            })
            .catch(err => {
                errorCB(err)
            })
    },
    getSpotsByMapFilters(data, cb, errorCB) {
        axios.post(API_URL_CIT + 'getSpotsByMapFilters', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err)
            })
    },
    getTracksData(data, cb, errorCB) {
        axios
            .post(API_URL_CIT + 'getTracks', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getFixLocation(data, cb, errorCB) {
        axios.post(API_URL_CIT + 'getFixLocation', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    fixLocationsDropdown(params, cb, errorCB) {
        axios
            .post(API_URL + 'fixLocationsDropdown', params)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
}
export default API
